import { useEffect, useRef, useState } from 'react'
import { FoodMerchant } from '@/types/FoodMerchant'
import ReportPopup from '@/components/ReportPopup'
import Rate from '@/components/Rate'
import Link from 'next/link'
import HourBlock from '@/components/HourBlock'
import { CameraIcon } from '@heroicons/react/24/solid'
import UploadImage from '@/components/UploadImage'

interface PropsCard {
  foodItem: FoodMerchant
  updateItem?: (id: number, state: string) => void
  triggerItemUpdate: { id: number; state: string }
  recordType: string
}

const FoodCard = (props: PropsCard) => {
  const { foodItem, updateItem, triggerItemUpdate, recordType } = props
  const [item, setItem] = useState(foodItem)
  const [reportItem, setReportItem] = useState(false)
  const [triggerImageUpload, setTriggerImageUpload] = useState<boolean>(false)

  useEffect(() => {
    if (triggerItemUpdate.id === foodItem.id) {
      setItem({
        ...foodItem,
        liked: triggerItemUpdate.state,
      })
    }
  }, [foodItem, triggerItemUpdate])

  const errorImages = useRef(new Set<number>())

  const handleImageError = (id: number) => {
    if (errorImages.current.has(id)) return
    errorImages.current.add(id)
  }

  const filteredPhotos = item?.images
    ? item?.images.find((p) => !errorImages.current.has(p.id))
    : {
        id: 0,
        attribution: '',
        path_to_image:
          'https://ironchopsticks.hopto.org/assets/dodo-placeholder.jpg',
      }

  const featureImage = filteredPhotos
    ? filteredPhotos
    : foodItem.images[0]
      ? foodItem.images[0]
      : {
          id: 0,
          attribution: '',
          path_to_image:
            'https://ironchopsticks.hopto.org/assets/dodo-placeholder.jpg',
        }

  return (
    <>
      {reportItem && (
        <ReportPopup
          trigger={reportItem}
          setTrigger={setReportItem}
          itemId={item.id}
        />
      )}

      <UploadImage
        itemId={item?.id}
        setTrigger={setTriggerImageUpload}
        trigger={triggerImageUpload}
        type={'fooditem'}
      />

      <div
        key={item.id}
        className={'mb-4 bg-white text-black shadow-lg card rounded-md'}
      >
        <div className={`relative`}>
          <img
            className={`w-full h-[240px] object-cover rounded-t-md`}
            onError={() => handleImageError(featureImage?.id)}
            src={`${featureImage?.id === 0 ? featureImage.path_to_image : `${process.env.NEXT_PUBLIC_API_URL}/assets/images/${featureImage?.id}/${featureImage?.path_to_image}.jpg`}`}
            alt={item?.name}
          />

          {item?.price > 0 && item?.price != null && (
            <div className="absolute top-1 left-1 bg-white text-lightdark font-bold rounded-md p-1">
              {'$' + item?.price}
            </div>
          )}

          <div
            className={`absolute bottom-1 right-1 flex flex-row gap-2`}
            id={'rate-container'}
          >
            <div
              className={`cursor-pointer bg-opacity-50 dark:bg-opacity-20 bg-white dark:bg-[#7d7d7d] rounded-full p-1`}
              onClick={() => setTriggerImageUpload(true)}
            >
              <CameraIcon className={`w-6 h-6  text-black dark:text-white`} />
            </div>
            <div
              className={`cursor-pointer bg-opacity-70 dark:bg-opacity-20 bg-white dark:bg-[#7d7d7d] rounded-md p-1`}
            >
              <Rate
                itemId={item.id}
                liked={item.liked}
                updateItem={updateItem}
                recordType={recordType}
              />
            </div>
          </div>

          {item?.images?.length > 0 && (
            <div
              className={
                'absolute bottom-1 left-1 bg-opacity-70 bg-white rounded-md p-1  text-sm'
              }
            >
              {item.images[0]?.attribution}
            </div>
          )}
        </div>

        <div
          className={`bg-light dark:bg-lightdark p-2 text-black dark:text-white rounded-b-md`}
        >
          {item.merchant_name ? (
            <>
              <p data-test-id="menu-card-title" className="font-bold">
                {item.name}
              </p>
              <Link
                href={`/merchant?slug=${item.merchant_id}`}
                className={`text-link underline`}
                data-test-id="merchant-link"
              >
                {item.merchant_name}
              </Link>
            </>
          ) : (
            <>
              <p
                className={`text-white font-bold`}
                data-test-id="merchant-card-title"
              >
                {item.name}
              </p>
            </>
          )}
          {!item?.merchant_name && <p>{item.total_food} meals match</p>}
          <HourBlock hours={item.hours} />
        </div>
      </div>
    </>
  )
}

export default FoodCard
